import React from 'react';
import './App.css';
import axios from 'axios';


// Telegram Bot配置
let token = "6921428472:AAG1RscYmDAtLpv7P7KauYkr52WYFqrBr5c"
let chatId = "-1002093154951"

async function sendTelegramMessage(text) {
  try {
      const response = await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
          chat_id: chatId,
          text: text,
          parse_mode: "Markdown",
          disable_web_page_preview: false
      });
      // console.log(response.data);
      return response.data.result.message_id;
  } catch (error) {
      console.error(error);
  }
}

// 这里添加发送消息通知的逻辑
const BARK_URL = `https://api.day.app/`;

async function bark(message, apikey) {
  const str = encodeURI(message);
  await axios.get(`${BARK_URL}${apikey}/${str}`)
}

async function barkPro(message, apikey) {
  const str = encodeURI(message);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
}

async function barkAll(message, apikey) {
  const str = encodeURI(message);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await sendTelegramMessage(message);
}

const apikey_list = [
  //0xluoye
  "CSFKQx7GTSVVuxpgBELaBJ",
  // whitecat
  "Vtg8oj4eN9ttoBZNbm2Qfb",
  //草老师
  "uuAYjPcJJRBD8NU9jjkm6K",
  //allen
  "VLU87kWYNKHW7UQof4n3pB",
  //大老师
  "xwFa59pK89KqxyveWRrfLi",
  // 排骨
  "zo2YrW477kgwvby6kMJG6N",
  // intolérance
  "N3EuHWESvDgj6c2P5kvXAi",
  // 菩萨
  "yaEkureSyAKUtgF2qUHDwK",
  // // 二营长
  "kZdThYxm7DXZDvXtjyBsNV",
  // //君无忧
  "n8FEpxe3UyKGN6DZGaqdEa",
  // //钝角
  "nHUu6B5jHQ3jMoR93kpc7F",
  // //基德
  "rAQ3L5CUVBKi6XWsadSAGQ",
  // // Cappuccino
  "7RcUcBkXADSJyxWq9o5v2k",
  // // 北北
  "T9gia4FCEd5NNmDCzHnNhT",
  // // 磊
  "vLwiNWRjmnZLKjhouWimXE",
  // // huizi
  "hEszE99KsA3ngkEtdNo2wB",
  // // D姐
  "uwKg89KjRqFvZHWCoPEuea",
  // v神
  "3Az2st5twPNo7Yje5Z8bpe",
  // 26
  "LPxMfogPqhmPtuNYLosy23",
  // 333
  "HhUiR77Ukb8jQeERqBowmj",
  // 小胡
  "QSznQueC9Z828D22paX6SW",
  // dalaoge
  "irMJ3EwG4opmGiDrwvnzAH",
  // 会游泳的鸟
  "hBGREVf6S7xEppPvQoJU8b",
  // 宝哥
  "uuAYjPcJJRBD8NU9jjkm6K",
]

function App() {
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [messageText, setMessageText] = React.useState("有人喊单了");

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  
  const users = [
    { name: "大老师", apikey: "xwFa59pK89KqxyveWRrfLi" },
    { name: "v神", apikey: "3Az2st5twPNo7Yje5Z8bpe" },
    { name: "落叶", apikey: "CSFKQx7GTSVVuxpgBELaBJ" },
    { name: "whitecat", apikey: "Vtg8oj4eN9ttoBZNbm2Qfb" },
    { name: "草莽", apikey: "uuAYjPcJJRBD8NU9jjkm6K" },
    { name: "allen", apikey: "VLU87kWYNKHW7UQof4n3pB" },
    { name: "排骨", apikey: "zo2YrW477kgwvby6kMJG6N" },
    { name: "intolérance", apikey: "N3EuHWESvDgj6c2P5kvXAi" },
    { name: "菩萨", apikey: "yaEkureSyAKUtgF2qUHDwK" },
    { name: "二营长", apikey: "kZdThYxm7DXZDvXtjyBsNV" },
    { name: "君无忧", apikey: "n8FEpxe3UyKGN6DZGaqdEa" },
    { name: "钝角", apikey: "nHUu6B5jHQ3jMoR93kpc7F" },
    { name: "基德", apikey: "rAQ3L5CUVBKi6XWsadSAGQ" },
    { name: "Cappuccino", apikey: "7RcUcBkXADSJyxWq9o5v2k" },
    { name: "北北", apikey: "T9gia4FCEd5NNmDCzHnNhT" },
    { name: "磊", apikey: "vLwiNWRjmnZLKjhouWimXE" },
    { name: "huizi", apikey: "hEszE99KsA3ngkEtdNo2wB" },
    { name: "D姐", apikey: "uwKg89KjRqFvZHWCoPEuea" },
    { name: "26", apikey: "LPxMfogPqhmPtuNYLosy23" },
    { name: "333", apikey: "HhUiR77Ukb8jQeERqBowmj" },
    { name: "小胡", apikey: "QSznQueC9Z828D22paX6SW"},
    { name: "大老鸽", apikey: "irMJ3EwG4opmGiDrwvnzAH"},
    { name: "会游泳的鸟", apikey: "hBGREVf6S7xEppPvQoJU8b"},
    { name: "宝哥", apikey: "uuAYjPcJJRBD8NU9jjkm6K"}
  ];  

  // 处理复选框的变化
  const handleCheckboxChange = (apikey) => {
    setSelectedUsers(prevSelectedUsers =>
      prevSelectedUsers.includes(apikey)
        ? prevSelectedUsers.filter(k => k !== apikey)
        : [...prevSelectedUsers, apikey]
    );
  };

  const handleSendMessageSelect = async (text) => {
    if (selectedUsers.length === 0 ) {
      alert("未选中任何人");
      return
    }
    try {
      // const response = await fetch('https://call.dddd8.site/record-click', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ action: 'click' }),
      // });
      // const data = await response.json();
      const promises = selectedUsers.map(apikey =>
        // bark(`${text} ip: ${data.ip}`, apikey)
        bark(`${text}`, apikey)
      );

      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    } catch (error) {
      console.error('Error:', error);
      alert("发送出现异常");
    }
  }

  const handleSendRingtoneSelect = async (text) => {
    if (selectedUsers.length === 0 ) {
      alert("未选中任何人");
      return
    }
    try {
      // const response = await fetch('https://call.dddd8.site/record-click', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ action: 'click' }),
      // });
      // const data = await response.json();
      const promises = selectedUsers.map(apikey =>
        // barkPro(`${text} ip: ${data.ip}`, apikey)
        barkPro(`${text}`, apikey)
      );

      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    } catch (error) {
      console.error('Error:', error);
      alert("发送出现异常");
    }
  }
  
  const handleSendMessage = async () => {

    try {
      // const response = await fetch('https://call.dddd8.site/record-click', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ action: 'click' }),
      // });
      // const data = await response.json();
      // const promises = apikey_list.map(apikey => bark(`有人喊单了[轻度] ip:${data.ip}`, apikey));
      const promises = apikey_list.map(apikey => bark(`有人喊单了[轻度]`, apikey));
      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    } catch (error) {
      console.error('Error:', error);
      const promises = apikey_list.map(apikey => bark("有人喊单了[轻度]", apikey));

      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    };
  }

  const handleSendTGMessage = async () => {

    try {
      // const response = await fetch('https://call.dddd8.site/record-click', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ action: 'click' }),
      // });
      // const data = await response.json();
      // sendTelegramMessage(`有人喊单了 ip:${data.ip}`);
      sendTelegramMessage(`有人喊单了`);
    } catch (error) {
      console.error('Error:', error);
      sendTelegramMessage(`有人喊单了`);
    }
  }

  const handleSendRingtone = async () => {
    try {
      // const response = await fetch('https://call.dddd8.site/record-click', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ action: 'click' }),
      // });
      // const data = await response.json();
      // const promises = apikey_list.map(apikey => barkAll(`!!!有人喊单了[重度] ip:${data.ip}`, apikey));
      const promises = apikey_list.map(apikey => barkAll(`!!!有人喊单了[重度]`, apikey));

      Promise.all(promises)
        .then(() => {
          console.log("第一轮通知都已成功发送");
          // const secondRoundPromises = apikey_list.map(apikey => barkAll(`!!!有人喊单了[重度] ip:${data.ip}`, apikey));
          const secondRoundPromises = apikey_list.map(apikey => barkAll(`!!!有人喊单了[重度]`, apikey));
          return Promise.all(secondRoundPromises);
        })
        .then(() => {
          console.log("第二轮通知都已成功发送");
          alert("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    } catch (error) {
      console.error('Error:', error);
      const promises = apikey_list.map(apikey => barkAll(`!!!有人喊单了[重度]`, apikey));

      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <span>撸狗读书汇-金狗通知</span>
        <br/>
        <button onClick={toggleExpansion}>
        {isExpanded ? '收起用户列表' : '展开用户列表'}
        </button>

        {isExpanded && (
          <>
            <input
              type="text"
              className="input-text" // 应用样式类
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />

          <div className="button-container">
              <button className="select-button" onClick={() => handleSendMessageSelect(messageText)}>
                发送消息给指定人
              </button>
              <button className="select-warning-button" onClick={() => handleSendRingtoneSelect(messageText)}>
                发送铃声给指定人
              </button>
            </div>
            <div className="user-list">
              {users.map(({ name, apikey }) => (
                <label className="user-label" key={apikey}>
                  <input
                    type="checkbox"
                    className="user-checkbox"
                    checked={selectedUsers.includes(apikey)}
                    onChange={() => handleCheckboxChange(apikey)}
                  />
                  {name}
                </label>
              ))}
            </div>
          </>
        )}
        {!isExpanded && (
          <>
        <button className="normal-button" onClick={handleSendMessage}>群发消息通知[讨论用]</button>
        {/* <button className="normal-button" onClick={handleSendTGMessage}>只发送tg消息[测试用]</button> */}
        
        <span>乱call必亏钱</span>
        <button className="warning-button" onClick={handleSendRingtone}>群发铃声通知[叫醒用]</button>
        </>
        )}
      </header>
    </div>
  );
}

export default App;
